@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

body, html {
  margin: 0;
  padding: 0;

  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --white-1: #FDFDFD;
  --white-2: #F4F4F4;
  --blue-1: #174090;
  --blue-1-darker: #143578;
  --blue-1-lighter: #1a48a5;
  --blue-2: #00afe6;
  --blue-2-darker: #009ac9;
  --blue-2-lighter: #01bef7;
  --gray: #a5aaad;
  --gray-lighter: #d5dbdf; 
  --gray-darker: #8f9396;
}

body {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  display: none;
}