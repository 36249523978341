.carousel2 {
    height: 600%;

    display: inline-flex;

    position: relative;

    left: 0;
    transition: transform 0.5s;
}

.carousel2_images_container {
    height: calc(100% / 6);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
}

.carousel2_image {
    height: 100%;
}

.carousel2_img {
    height: 100%;
}