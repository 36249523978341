.navbar {
    height: 5rem;
    width: 100vw;

    display: flex;
    justify-content: center;

    position: sticky;
    top: 0;
    right: 0;
    z-index: 1000;

    background-color: var(--blue-1);
    /*
    background-image: url(../assets/images/patterns/patron2_light.png);
    background-size: auto 5rem;
    background-repeat: repeat-x;
    */

    box-shadow: 0 0.2rem 1rem #00000033;
}

.navbar_container {
    width: calc(100vw - 10vw);

    display: flex;
    justify-content: space-between;
}

.navbar_container_left {
    height: 100%;
    width: auto;

    display: flex;
    align-items: center;
}

.logo_container {
    height: auto;
}

.logo {
    height: 1.8rem;
    visibility: hidden;
}

.navbar_container_right {
    height: 100%;
    width: auto;

    display: flex;
    align-items: center;
}

.elements_container {
    display: flex;
    gap: 5vw;
}

.elements_container ul {
    display: flex;
    align-items: center;

    list-style-type: none;

    margin: 0;
    padding: 0;

    gap: 1.5vw;
}

.elements_container ul span {
    text-decoration: none;
    
    display: flex;
    align-items: flex-end;

    cursor: pointer;

    user-select: none;
}

.svg_icons {
    width: 3vh;
}

.nav_item {
    font-weight: 500;
    color: var(--white-1);

    display: flex;
    justify-content: center;
    align-items: center;
}

.nav_item:hover {
    font-weight: 500;
    color: var(--blue-2);
}

.hamburguer_menu {
    display: none;
}


/* Small screens */
@media only screen and (max-width:600px) {
    .hamburguer_menu {
        display: flex;
        height: 100%;
        width: 2rem;
        align-items: center;
    }

    .hamburguer_menu .svg_icons {
        width: 2rem;
        height: 2rem;
    }

    .elements_container {
        height: calc(100vh - 5rem);
        width: 0px;
        
        position: fixed;
        
        right: 0;
        top: 5rem;
        
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        gap: 2rem;
        padding-top: 2rem;


        background-color: var(--blue-1);
        transition: width 150ms ease-in;
    }
    
    .elements_container.active {
        width: 13rem;
    }

    .elements_container ul {
        display: none;
    }

    .elements_container.active ul {
        width: calc(13rem - 10vw);

        display: flex;
        flex-direction: column;
        align-items: flex-end;

        gap: 2rem;
    }

    .elements_container ul:last-child {
        flex-direction: row;
        justify-content: space-between;

        gap: 0;
    }
    
    .elements_container ul li {
        height: auto;
    }

    .elements_container ul span{
        text-align: end;
    }

    .logo {
        visibility: visible;
    }
}

@media only screen and (max-width:915px) and (orientation:landscape) {
    .hamburguer_menu {
        display: flex;
        height: 100%;
        width: 2rem;
        align-items: center;
    }

    .hamburguer_menu .svg_icons {
        width: 2rem;
        height: 2rem;
    }

    .elements_container {
        height: calc(100vh - 5rem);
        width: 0px;
        
        position: fixed;
        
        right: 0;
        top: 5rem;
        
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        gap: 2rem;
        padding-top: 2rem;


        background-color: var(--blue-1);
        transition: width 150ms ease-in;
    }
    
    .elements_container.active {
        width: 13rem;
    }

    .elements_container ul {
        display: none;
    }

    .elements_container.active ul {
        width: calc(13rem - 10vw);

        display: flex;
        flex-direction: column;
        align-items: flex-end;

        gap: 2rem;
    }

    .elements_container ul:last-child {
        flex-direction: row;
        justify-content: space-between;

        gap: 0;
    }
    
    .elements_container ul li {
        height: auto;
    }

    .elements_container ul span{
        text-align: end;
    }

    .logo {
        visibility: visible;
    }
}