.blog_container {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.blog_container_title {
    height: 8vh;
    width: 100%;

    margin: 0;
    padding: 2vh 0 2vh 0;
    
    color: var(--white-1);
}

.card_container {
    height: 90%;
    width: 100%;

    display: flex;
    justify-content: space-between;

    gap: 2vw;
}

.card {
    max-width: 30%;

    display: grid;
    grid-auto-rows: auto 55% 15%;
    row-gap: 1vh;

    padding: 2rem;

    border-radius: 0.5rem;
    box-shadow: 0 0.2rem 0.3rem #00000086;

    background-color: var(--white-1);
}

.card_title {
    margin: 0;
    height: min-content;
    font-family: 'Poppins', sans-serif;

    overflow: hidden;
}

.card_text_full {
    margin: 0;

    text-align: left;
    word-break: normal;
    font-style: italic;

    overflow-y: scroll;
    overflow-x: hidden;
    
    font-family: 'Poppins', sans-serif;
}

.card_text_section {
    margin: 0;

    text-align: left;
    word-break: normal;
    font-style: italic;

    overflow: hidden;
    
    font-family: 'Poppins', sans-serif;
}


.card_text::-webkit-scrollbar {
    width: 0.5vw;
}
.card_text::-webkit-scrollbar-track {
    background: var(--gray-light);
}
.card_text::-webkit-scrollbar-thumb {
    background: var(--blue-2);
}

.read_more {
    margin: 0;
}

@media only screen and (max-width:600px) {
    .blog_container_title {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    
        margin: 0;
        padding: 2vh 0 2vh 0;
        
        color: var(--white-1);
    }

    .card_container {
        width: 100%;
    
        display: flex;
        flex-direction: column;
        justify-content: space-between;

    
        gap: 2vh;
    }

    .card {
        max-width: 100%;
        min-height: 30%;
        max-height: 30%;

        padding: 2rem;

        display: grid;
        grid-auto-rows: 30% 50% 20%;
        row-gap: 1vh;

        border-radius: 0.5rem;
        box-shadow: 0 0.2rem 0.3rem #00000086;

        background-color: var(--white-1);
    }

    .card_title {
        margin: 0;
    
        font-family: 'Poppins', sans-serif;
    }

    .card_text {
        margin: 0;
    
        text-align: justify;
        word-break: normal;
        font-style: italic;
        
        font-family: 'Poppins', sans-serif;
    }
}

@media only screen and (max-width:915px) and (orientation:landscape) {
    .blog_container_title {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    
        margin: 0;
        padding: 2vh 0 2vh 0;
        
        color: var(--white-1);
    }

    .card_container {
        width: 100%;
    
        display: flex;
        flex-direction: column;
        justify-content: space-between;

    
        gap: 2vh;
    }

    .card {
        max-width: 100%;
        min-height: 30%;
        max-height: 30%;

        padding: 2rem;

        display: grid;
        grid-auto-rows: 30% 50% 20%;
        row-gap: 1vh;

        border-radius: 0.5rem;
        box-shadow: 0 0.2rem 0.3rem #00000086;

        background-color: var(--white-1);
    }

    .card_title {
        margin: 0;
    
        font-family: 'Poppins', sans-serif;
    }

    .card_text {
        margin: 0;
    
        text-align: justify;
        word-break: normal;
        font-style: italic;
        
        font-family: 'Poppins', sans-serif;
    }
}