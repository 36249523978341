.carouselmobile2 {
    overflow: hidden;
    width: 600%;

    display: flex;

    position: relative;

    bottom: 0;
    transition: transform 0.5s;
}

.carouselmobile2_images_container {
    display: flex;
    align-items: flex-start;
}

.carouselmobile2_image {
    width: 100%;
    display: flex;
    align-items: flex-end;
}

.carouselmobile2_img {
    width: 100%;
}