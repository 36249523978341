.section_container {
    height: 100%;
    width: calc(100vw - 10vw);

    display: flex;
    justify-content: center;
    align-items: center;
}

/* Section 1 */
/* --------------------------------------------------------------------- */
.section_1 {
    height: calc(100vh - 5rem);
    width: 100vw;
    
    display: flex;
    justify-content: center;

    background-color: var(--blue-1);
    /*
    background-image: url(../assets/images/patterns/patron3_light.png);
    background-size: auto 15rem;
    background-repeat: repeat;
    */


    scroll-margin-top: 5rem;
}

.section_1_container_left {
    height: 100%;
    width: 50%;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.logohome {
    height: auto;
    width: 80%;

}

.header {
    font-size: 3.1vw;
    font-weight: 200;

    margin: 0;

    text-indent: -0.2rem;

    color: var(--blue-2);
}

.description {
    font-size: 1.2rem;
    font-weight: 400;

    word-break: normal;
    white-space: normal;
    text-align: left;

    margin: 1rem 0 1rem 0;

    color: var(--white-2);
}

.buttons_container {
    display: flex;
    gap: 1rem;
    flex: nowrap;
}

.learnmore {
    font-size: 1.2rem;
    font-weight: 400;

    padding: 0.5rem 1rem 0.5rem 1rem;

    border: none;

    margin: 1rem 0 1rem 0;
    
    border-radius: 1rem;

    color: var(--white-2);
    background-color: var(--blue-2);
}
.learnmore:hover {
    background-color: var(--blue-2-darker);
    cursor: pointer;
}
.learnmore:active {
    background-color: var(--blue-1-lighter);
}

.section_1_container_right {
    height: 100%;
    width: 50%;

    display: flex;
    justify-content: flex-end;
}

.carousel_desktop {
    width: 100%;

    margin-right: -5vw;

    overflow-y: hidden;
    overflow-x: hidden;
}

.carousel_images {
    height: 100%;
    object-fit: cover;
}

.carousel_mobile {
    display: none;
}

.carousel_images_mobile {
    display: none;
}

@media only screen and (max-width:600px) {
    .section_1 {
        height: auto;
    }

    .section_1 .section_container {
        height: 100%;
        width: calc(100vw - 10vw);
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .section_1_container_left {
        height: 60%;
        width: 100%;
    
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end   ;

        margin: 2rem 0 0 0;
    }

    .header {
        font-size: 2.5rem;
        font-weight: 500;
    
        margin: 0;
    
        text-align: center;
        text-indent: -0.2rem;
    }
    
    .description {
        font-weight: 400;
    
        word-break: normal;
        white-space: normal;
        text-align: center;
    
        margin: 1rem 0 1rem 0;
    
        color: var(--white-2);
    }

    .buttons_container {
        flex-direction: column;
        align-items: center;

        margin: 1rem 0 1rem 0;
    }

    .learnmore {
        width: max-content;
    }
    
    .learnmore {
        font-weight: 400;
    
        word-break: normal;
        white-space: normal;
        text-align: center;
    
        margin: 0;
    
        color: var(--white-2);
    }

    .section_1_container_right {
        height: 35%;
        width: 100vw;
    
        display: flex;
        justify-content: flex-end;
    }

    .carousel_desktop {
        display: none;
    }

    .carousel_images {
        display: none;
    }

    .carousel_mobile {
        width: 100%;
        display: block;

        overflow-x: hidden;
    }

    .carousel_images_mobile {
        display: block;
        height: 100%;
        width: 100%;
    }
}

@media only screen and (max-width:915px) and (orientation:landscape) {
    .section_1 {
        height: auto;
    }

    .section_1 .section_container {
        height: 100%;
        width: calc(100vw - 10vw);
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .section_1_container_left {
        height: 60%;
        width: 100%;
    
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end   ;

        margin: 2rem 0 0 0;
    }

    .header {
        font-size: 2.5rem;
        font-weight: 500;
    
        margin: 0;
    
        text-align: center;
        text-indent: -0.2rem;
    }
    
    .description {
        font-weight: 400;
    
        word-break: normal;
        white-space: normal;
        text-align: center;
    
        margin: 1rem 0 1rem 0;
    
        color: var(--white-2);
    }

    .buttons_container {
        flex-direction: column;
        align-items: center;

        margin: 1rem 0 1rem 0;
    }

    .learnmore {
        width: max-content;
    }
    
    .learnmore {
        font-weight: 400;
    
        word-break: normal;
        white-space: normal;
        text-align: center;
    
        margin: 0;
    
        color: var(--white-2);
    }

    .section_1_container_right {
        height: 35%;
        width: 100vw;
    
        display: flex;
        justify-content: flex-end;
    }

    .carousel_desktop {
        display: none;
    }

    .carousel_images {
        display: none;
    }

    .carousel_mobile {
        width: 100%;
        display: block;

        overflow-x: hidden;
    }

    .carousel_images_mobile {
        display: block;
        height: 100%;
        width: 100%;
    }
}


/* Section 2 */
/* --------------------------------------------------------------------- */
.section_2 {
    height: auto;
    min-height: 75vh;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: var(--white-2);

    scroll-margin-top: 5rem;

    padding: 2rem 0 2rem 0;
}

.section_2 .section_container {
    flex-direction: column;
    justify-content: space-around;    
}

.section_2_container_left {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.header2 {
    font-size: 2.5rem;
    font-weight: 500;

    margin: 0;

    text-indent: -0.2rem;

    color: var(--blue-1);
}

.description2 {
    font-size: 1.2rem;
    font-weight: 400;


    text-align: left;

    color: var(--blue-1);
}

.medios_container {
    display: flex;
    flex-wrap: wrap;

    margin: 1rem 0 1rem 0;

    gap: 2rem;
    row-gap: 1rem;
}

.medios {
    height: 6rem;
    width: 6rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    box-shadow: 0 0.2rem 0.3rem #00000023;

    gap: 1vh;
    padding: 1rem;

    border-radius: 0.5rem;
    border: none    ;
    color: var(--blue-1);
    background-color: var(--white-1);
}
.medios:hover {
    background-color: var(--gray);
}
.medios:active {
    background-color: var(--gray-darker);
}

.medios_container a {
    text-decoration: none;   
}

.svg_icons_home {
    width: 50%;
}

.section_2_container_right {
    height: 60%;
    width: 100%;

    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.godcastkids_container {
    width: 70%;

    display: flex;
    flex-direction: column;

    padding: 2rem;

    align-items: center;
    justify-content: flex-start;

    border-radius: 1rem;
    background-color: var(--white-1);
    box-shadow: 0 0.2rem 0.3rem #00000023;
}

.godcastkids_video::-webkit-media-controls-panel {
    background-image: none !important;
}

.godcastkids_video {
    width: 100% ;
    
    border-radius: 1rem;
}

.godcastkids_description {
    font-size: 1rem;
    color: black;

    overflow-y: scroll;
    
    text-align: justify;
}

.godcastkids_button {
    width: 15vw;
    font-size: 1.2rem;
    font-weight: 400;

    padding: 0.5rem 1rem 0.5rem 1rem;

    border: none;

    
    border-radius: 1rem;

    color: var(--white-2);
    background-color: var(--blue-2);
}

.godcastkids_button:hover {
    background-color: var(--blue-2-darker);
    cursor: pointer;
}
.godcastkids_button:active {
    background-color: var(--blue-1-lighter);
}

.team_carousel_container {
    height: 30rem;
    width: 100%;

    overflow: hidden;
}


@media only screen and (max-width:600px) {
    .section_2 {
        height: auto;
    }
    
    .section_2 .section_container {
        height: auto;
        width: calc(100vw - 10vw);
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .section_2_container_left {
        height: auto;
        width: 100%;
    
        display: flex;
        flex-direction: column;
        justify-content: center;

        margin: 1rem 0 1rem 0;
    }

    .header2 {
        font-size: 2.5rem;
        font-weight: 500;
    
        margin: 0;
        text-align: center;
    
        text-indent: -0.2rem;
    
        color: var(--blue-1);
    }
    
    .description2 {
        font-weight: 400;
    
        text-align: center;
    
        color: var(--blue-1);
    }

    .medios_container {
        width: 100%; 

        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .medios_container a {
        width: 20%;
    }

    .medios {
        height: fit-content;
        width: 100%;
        padding: 0.5rem;
    }

    .medios span {
        display: none;
    }
    
    .section_2_container_right {
        height: auto;
        width: 100%;
    
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 1rem 0 1rem 0;
    }

    .godcastkids_button {
        width: 100%;
    }

    .team_carousel_container {
        height: 30rem;
        width: 100%;
    
        overflow: auto;
    }
}

@media only screen and (max-width:915px) and (orientation:landscape) {
    .section_2 {
        height: auto;
    }
    
    .section_2 .section_container {
        height: auto;
        width: calc(100vw - 10vw);
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .section_2_container_left {
        height: auto;
        width: 100%;
    
        display: flex;
        flex-direction: column;
        justify-content: center;

        margin: 1rem 0 1rem 0;
    }

    .header2 {
        font-size: 2.5rem;
        font-weight: 500;
    
        margin: 0;
        text-align: center;
    
        text-indent: -0.2rem;
    
        color: var(--blue-1);
    }
    
    .description2 {
        font-weight: 400;
    
        text-align: center;
    
        color: var(--blue-1);
    }

    .medios_container {
        width: 100%; 

        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .medios_container a {
        width: 20%;
    }

    .medios {
        height: fit-content;
        width: 100%;
        padding: 0.5rem;
    }

    .medios span {
        display: none;
    }
    
    .section_2_container_right {
        height: auto;
        width: 100%;
    
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 1rem 0 1rem 0;
    }

    .godcastkids_button {
        width: 100%;
    }

    .team_carousel_container {
        height: 30rem;
        width: 100%;
    
        overflow: auto;
    }
}

/* Section 3 */
/* --------------------------------------------------------------------- */
.section_3 {
    height: calc(100vh - 5rem);
    width: 100%;

    display: flex;
    justify-content: center;

    background-color: var(--blue-1);
    /*
    background-image: url(../assets/images/patterns/patron3_light.png);
    background-size: auto 15rem;
    background-repeat: repeat;
    */

    scroll-margin-top: 5rem;

    padding: 2rem 0 2rem 0;
}

.section_3_container_left {
    height: 100%;
    width: 50%;
}

.latestpost_container {
    height: 98%;
    width: 95%;
}

.section_3_container_right {
    height: 100%;
    width: 50%;

    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.mediumblog_container {
    width: 95%;
    height: 50%;
}


@media only screen and (max-width:600px) {
    .section_3 {
        height: auto;

    }

    .section_3 .section_container {
        height: 100%;
        width: calc(100vw - 10vw);
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .section_3_container_left {
        height: 50%;
        width: 100%;
    }
    
    .latestpost_container {
        height: 40rem;
        width: 100%;
    }
    
    .section_3_container_right {
        height: 50%;
        width: 100%;
    
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    
    .mediumblog_container {
        height: 50rem;
        width: 100%;
    }
}

@media only screen and (max-width:915px) and (orientation:landscape) {
    .section_3 {
        height: auto;

    }

    .section_3 .section_container {
        height: 100%;
        width: calc(100vw - 10vw);
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .section_3_container_left {
        height: 50%;
        width: 100%;
    }
    
    .latestpost_container {
        height: 40rem;
        width: 100%;
    }
    
    .section_3_container_right {
        height: 50%;
        width: 100%;
    
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    
    .mediumblog_container {
        height: 50rem;
        width: 100%;
    }
}

/* Section 4 */
/* --------------------------------------------------------------------- */
.section_4 {
    height: auto;
    min-height: 75vh;
    width: 100vw;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--white-2);

    scroll-margin-top: 5rem;
}

.section_4 .section_container {
    height: 100%;
}

.section_4_container_left {
    height: 100%;
    width: 50%;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.donation_container {
    height: 30rem;
    width: 30rem;

    display: flex;
    flex-direction: column;

    padding: 2rem;

    align-items: center;
    justify-content: center;

    gap: 1rem;

    border-radius: 1rem;
    background-color: var(--white-1);
    box-shadow: 0 0.2rem 0.3rem #00000023;
}

.donation_container .amount_instructions {
    width: 100%;

    font-size: 0.9rem;
    font-style: italic;

    text-align: center;

    color: var(--gray);
}

.paypal_container {
    height: 80%;
    width: 80%;
}

.section_4_container_right {
    height: 100%;
    width: 50%;

    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.icon_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.svg_icons_paypal {
    height: 14vh;  
}

@media only screen and (max-width:600px) {
    .section_4 {
        height: auto;
        padding: 2rem 0 2rem 0;
    }

    .section_4 .section_container {
        height: 100%;
        width: calc(100vw - 10vw);
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


    .section_4_container_right {
        height: 30rem;
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .donation_container {
        height: 80%;
        width: 80%;
    
        display: flex;
        flex-direction: column;
    
        padding: 2rem;
    
        align-items: center;
        justify-content: center;
    
        border-radius: 1rem;
        background-color: var(--white-1);
        box-shadow: 0 0.2rem 0.3rem #00000023;
    }
    
    .paypal_container {
        height: 80%;
        width: 80%;
    }

    .section_4_container_left {
        height: 40%;
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-width:915px) and (orientation:landscape) {
    .section_4 {
        height: auto;
        padding: 2rem 0 2rem 0;
    }

    .section_4 .section_container {
        height: 100%;
        width: calc(100vw - 10vw);
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


    .section_4_container_right {
        height: 30rem;
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .donation_container {
        height: 80%;
        width: 80%;
    
        display: flex;
        flex-direction: column;
    
        padding: 2rem;
    
        align-items: center;
        justify-content: center;
    
        border-radius: 1rem;
        background-color: var(--white-1);
        box-shadow: 0 0.2rem 0.3rem #00000023;
    }
    
    .paypal_container {
        height: 80%;
        width: 80%;
    }

    .section_4_container_left {
        height: 40%;
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

/* Section 5 */
/* --------------------------------------------------------------------- */
.section_5 {
    height: auto;
    min-height: 75vh;

    width: 100vw;
    
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--blue-2);
    /*
    background-image: url(../assets/images/patterns/patron3_light.png);
    background-size: auto 15rem;
    background-repeat: repeat;
    */


    scroll-margin-top: 5rem;
}

.section_5_container_left {
    height: 100%;
    width: 50%;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.logogk {
    height: auto;
    width: 80%;
    margin-bottom: 20px;
}

.section_5_container_right {
    height: 100%;
    width: 50%;

    display: flex;
    justify-content: flex-end;
}

.gkimg {
    width: 100%;
    height: fit-content;
    transform: translate(120px, 0px);
}